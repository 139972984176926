import * as React from "react"

import licence_img from "../../images/creative-commons.jpg"
import "./Licence.scss"

const Licence = ({ additionClass }) => (
  <div className={`c-Licence ${additionClass ? additionClass : ""}`}>
    <span className="c-Licence__txt">
      Słownik dostępny jest na licencji Creative Common w&nbsp;wariancie
      licencyjnym CC BY SA (Creative Common)
    </span>
    <a
      className="c-Licence__img"
      href="https://creativecommons.org/licenses/by-sa/4.0/deed.pl"
      target="_blank"
      rel="noopener norefferer"
    >
      <img
        className="m-Footer__img"
        alt="Licencja “Open Access” - wariant licencji CC-BY-SA (Creative Commons) "
        src={licence_img}
      />
    </a>
  </div>
)

export default Licence
