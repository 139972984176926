/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import Seo from "../components/Seo"
import Header from "../modules/Header/Header"
import Footer from "../modules/Footer/Footer"
import Aside from "../modules/Aside/Aside"
import Search from "../modules/Search/Search"
import CheckApi from "../components/ChceckAPI/CheckApi"
import FontSizeChanger from "../components/FontSizeChanger/FontSizeChanger"

import "../styles/index.scss"

const LayoutSubpage = ({ children, title }) => {
  return (
    <div className="layout layout--subpage">
      <Seo title={title} />
      <CheckApi />
      <Header />
      <FontSizeChanger />
      <main className="main-content main-content--subpage">
        <Search />
        <div className="main-content__container">{children}</div>
      </main>
      <Aside />
      <Footer />
    </div>
  )
}

LayoutSubpage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutSubpage
