import * as React from "react"

import "./ShareButton.scss"

const ShareButton = props => {
  const { icon, title, click, disabled } = props
  return (
    <button
      className="c-ShareButton"
      title={title}
      onClick={click}
      disabled={disabled}
    >
      {icon}
    </button>
  )
}

export default ShareButton
