import React, { useState } from "react"

import ShareButton from "./ShareButton/ShareButton"
import "./ButtonTools.scss"

import Printer from "../../images/icons/printer.svg"
import Quotation from "../../images/icons/quotation.svg"
// import Download from "../../images/icons/download.svg"

const ButtonTools = ({ name }) => {
  const [showQuotation, setShowQuotation] = useState(false)

  const handleClickQuotation = () => {
    setShowQuotation(!showQuotation)
  }

  return (
    <div className="c-ButtonTools">
      <div className="c-ButtonTools__buttons">
        <ShareButton
          title="Drukuj hasło"
          icon={<Printer />}
          click={() => window.print()}
        />
        {/* <ShareButton title="Pobierz PDF" icon={<Download />} /> */}
        <ShareButton
          icon={<Quotation />}
          title="Wzór cytowania"
          click={handleClickQuotation}
        />
      </div>
      <div
        className={`c-ButtonTools__quotation ${
          showQuotation ? "c-ButtonTools__quotation--visible" : ""
        }`}
      >
        <span className="c-ButtonTools__quotationName">{name}</span> [hasło] w
        Przyczyna, W., Czarnecka, K., Ławreszuk, M. (red.). (2022).{" "}
        <em>Słownik polskiej terminologii prawosławnej</em>. Wydawnictwo
        Uniwersytetu w Białymstoku.
      </div>
    </div>
  )
}

export default ButtonTools
