import React from "react"

import "./Word.scss"

const Word = ({ data }) => {
  // Audio play method
  function playAudio(audioPath) {
    const audio = new Audio(`https://api-sptp.uwb.edu.pl${audioPath}`)
    audio.play()
  }

  return (
    <div className="c-Word">
      <div className="c-Word__wrapper">
        <div className="c-Word__heading">
          <div className="c-Word__entry">
            <h1 className="c-Word__colorBasic c-Word__bold">{data.name} </h1>
            <>
              {data.audio_path ? (
                <button
                  className="c-Word__audio c-Word__audio--heading"
                  onClick={() => {
                    playAudio(data.audio_path)
                  }}
                ></button>
              ) : (
                ""
              )}
            </>
          </div>

          <>
            {data.pronunciation ? (
              <div className="c-Word__pronunciation">
                [<em>wym.</em>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.pronunciation,
                  }}
                />{" "}
                ]
              </div>
            ) : (
              ""
            )}
          </>
        </div>

        <div className="c-Word__definition">
          {data.variants && data.variants.length > 0 && (
            <div className="c-Word__variants">
              {data.variants.map((variant, index) => (
                <div className="c-Word__variantsItem" key={index}>
                  <em className="c-Word__mr6">{variant.prefix}</em>
                  <span className="c-Word__colorBasic">
                    <a href={`/words/${variant.id}`}>{variant.name}</a>
                  </span>
                  {variant.pronunciation ? (
                    <div className="c-Word__pronunciation c-Word__pronunciation--variant">
                      [<em>wym.</em>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: variant.pronunciation,
                        }}
                      />{" "}
                      ]
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          )}
          {data.indexes && data.indexes.length > 0 && (
            <div className="c-Word__indexes">
              {data.indexes.map((itemIndex, index) => (
                <p key={index} className="c-Word__index">
                  <em>{itemIndex.index_type} .</em>
                  <span
                    className={`c-Word__indexItem ${
                      itemIndex.index_type === "cs" ? "c-Word__cs" : ""
                    }`}
                  >
                    {" "}
                    {itemIndex.name}
                  </span>
                  {itemIndex.audio_path ? (
                    <button
                      href={void 0}
                      className="c-Word__audio"
                      onClick={() => {
                        playAudio(itemIndex.audio_path)
                      }}
                    ></button>
                  ) : (
                    ""
                  )}
                </p>
              ))}
            </div>
          )}

          {data.shortcuts && data.shortcuts.length > 0 && (
            <div className="c-Word__shortcuts">
              <span className="c-Word__mr4">
                <em>w skrócie:</em>
              </span>
              {data.shortcuts.map((shortcut, index) => (
                <span className="c-Word__shortcutsItem" key={index}>
                  {shortcut.name}
                </span>
              ))}
            </div>
          )}

          {data.qualifiers && data.qualifiers.length > 0 && (
            <div className="c-Word__meaningQualifierList">
              {data.qualifiers.map((qualifier, index) => (
                <span className="c-Word__meaningQualifier" key={index}>
                  <em>{qualifier.shortcut}</em>
                </span>
              ))}
            </div>
          )}

          {data.description && (
            <div
              className="c-Word__meaningMain"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          )}

          {data.meanings && data.meanings.length > 0 && (
            <ol
              className={`c-Word__meanings ${
                data.homonyms && data.homonyms.length > 0
                  ? "c-Word__meanings--noList"
                  : ""
              }`}
            >
              {data.meanings.map((item, index) => (
                <li key={index} className="c-Word__meaning">
                  {data.homonyms && data.homonyms.length > 0 && (
                    <span className="c-Word__meaningHeading">{item.name}</span>
                  )}
                  <div className="c-Word__meaningContainer">
                    {item.indexes && item.indexes.length > 0 ? (
                      <span>
                        {item.indexes.map((itemIndex, index) => (
                          <span className="c-Word__meaningIndex" key={index}>
                            <em>{itemIndex.index_type}. </em>
                            <span
                              className={`${
                                itemIndex.index_type === "cs"
                                  ? "c-Word__cs"
                                  : ""
                              }`}
                            >
                              {itemIndex.name}
                            </span>
                            {itemIndex.audio_path ? (
                              <button
                                href={void 0}
                                className="c-Word__audio"
                                onClick={() => {
                                  playAudio(itemIndex.audio_path)
                                }}
                              ></button>
                            ) : (
                              ""
                            )}
                          </span>
                        ))}
                      </span>
                    ) : (
                      ""
                    )}

                    {item.qualifiers && item.qualifiers.length > 0
                      ? item.qualifiers.map((qualifier, index) => (
                          <span
                            className="c-Word__meaningQualifier"
                            key={index}
                          >
                            <em>{qualifier.shortcut}</em>
                          </span>
                        ))
                      : ""}

                    <div
                      className="c-Word__meaningMain"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>

                    {item.synonymus && item.synonymus.length > 0 ? (
                      <div className="c-Word__meaningSynonymus">
                        <em className="c-Word__mr4">Synonimy:</em>
                        {item.synonymus.map((synonym, index) => (
                          <p className="c-Word__meaningSynonym" key={index}>
                            {synonym.labels.map(
                              (label, index) =>
                                label && <em key={index}>{label}</em>
                            )}{" "}
                            <a href={`/words/${synonym.id}`}>{synonym.name}</a>
                          </p>
                        ))}
                      </div>
                    ) : (
                      ""
                    )}

                    {(item.noconnection && item.noconnection.length > 0) ||
                    (item.connections && item.connections.length > 0) ? (
                      <div className="c-Word__meaningConnection">
                        <em className="c-Word__mr4">Połączenia:</em>
                        {item.noconnection && item.noconnection.length > 0 && (
                          <div className="c-Word__meaningConnectionWrapper">
                            {item.noconnection.map((connection, index) =>
                              connection.indexes ? (
                                <div
                                  className="c-Word__meaningConnectionItem"
                                  key={index}
                                >
                                  <span className="c-Word__colorBasic">
                                    <strong>
                                      <a href={`/words/${connection.id}`}>
                                        {connection.name}
                                      </a>
                                    </strong>
                                    {connection.audio_path ? (
                                      <button
                                        href={void 0}
                                        className="c-Word__audio"
                                        onClick={() => {
                                          playAudio(connection.audio_path)
                                        }}
                                      ></button>
                                    ) : (
                                      ""
                                    )}
                                  </span>

                                  {connection.indexes.map(
                                    (itemIndex, index) => (
                                      <p className="" key={index}>
                                        <em>{itemIndex.index_type}. </em>
                                        <span
                                          className={` ${
                                            itemIndex.index_type === "cs"
                                              ? "c-Word__cs"
                                              : ""
                                          }`}
                                        >
                                          {itemIndex.name}
                                        </span>
                                        {itemIndex.audio_path ? (
                                          <button
                                            href={void 0}
                                            className="c-Word__audio"
                                            onClick={() => {
                                              playAudio(itemIndex.audio_path)
                                            }}
                                          ></button>
                                        ) : (
                                          ""
                                        )}
                                      </p>
                                    )
                                  )}
                                </div>
                              ) : (
                                <p
                                  className="c-Word__meaningConnectionWord"
                                  key={index}
                                >
                                  {connection.name}
                                </p>
                              )
                            )}
                          </div>
                        )}

                        {item.connections && item.connections.length > 0
                          ? item.connections.map((connection, index) =>
                              connection.indexes ? (
                                <div
                                  className="c-Word__meaningConnectionItem"
                                  key={index}
                                >
                                  <span className="c-Word__colorBasic">
                                    <strong>
                                      <a href={`/words/${connection.id}`}>
                                        {connection.name}
                                      </a>
                                    </strong>
                                  </span>

                                  {connection.audio_path ? (
                                    <button
                                      href={void 0}
                                      className="c-Word__audio"
                                      onClick={() => {
                                        playAudio(connection.audio_path)
                                      }}
                                    ></button>
                                  ) : (
                                    ""
                                  )}

                                  {connection.pronunciation ? (
                                    <div className="c-Word__pronunciation">
                                      [<em>wym.</em>{" "}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: connection.pronunciation,
                                        }}
                                      />{" "}
                                      ]
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  {connection.shortcuts &&
                                    connection.shortcuts.length > 0 && (
                                      <div className="c-Word__shortcuts">
                                        <span className="c-Word__mr4">
                                          <em>w skrócie:</em>
                                        </span>
                                        {connection.shortcuts.map(
                                          (shortcut, index) => (
                                            <span
                                              className="c-Word__shortcutsItem"
                                              key={index}
                                            >
                                              {shortcut.name}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    )}
                                  <div className="c-Word__meaningConnectionWrapper">
                                    {connection.indexes &&
                                    connection.indexes.length > 0
                                      ? connection.indexes.map(
                                          (itemIndex, index) => (
                                            <p
                                              className="c-Word__meaningConnectionIndex"
                                              key={index}
                                            >
                                              <em>{itemIndex.index_type}. </em>
                                              <span
                                                className={`${
                                                  itemIndex.index_type === "cs"
                                                    ? "c-Word__cs"
                                                    : ""
                                                }`}
                                              >
                                                {itemIndex.name}
                                              </span>
                                              {itemIndex.audio_path ? (
                                                <button
                                                  href={void 0}
                                                  className="c-Word__audio"
                                                  onClick={() => {
                                                    playAudio(
                                                      itemIndex.audio_path
                                                    )
                                                  }}
                                                ></button>
                                              ) : (
                                                ""
                                              )}
                                            </p>
                                          )
                                        )
                                      : ""}

                                    {connection.qualifiers &&
                                    connection.qualifiers.length > 0
                                      ? connection.qualifiers.map(
                                          (qualifier, index) => (
                                            <span
                                              className="c-Word__meaningQualifier"
                                              key={index}
                                            >
                                              <em>{qualifier.shortcut}</em>
                                            </span>
                                          )
                                        )
                                      : ""}

                                    {connection.description ? (
                                      <div
                                        className="c-Word__meaningConnectionDesc"
                                        dangerouslySetInnerHTML={{
                                          __html: connection.description,
                                        }}
                                      ></div>
                                    ) : (
                                      ""
                                    )}

                                    {connection.meanings &&
                                      connection.meanings.length > 0 && (
                                        <ol className="c-Word__meanings">
                                          {connection.meanings.map(
                                            (meaning, index) => (
                                              <li
                                                className="c-Word__meaning"
                                                key={index}
                                              >
                                                <div
                                                  className="c-Word__meaningConecntions"
                                                  dangerouslySetInnerHTML={{
                                                    __html: meaning.description,
                                                  }}
                                                ></div>
                                              </li>
                                            )
                                          )}
                                        </ol>
                                      )}

                                    {connection.synonymus &&
                                      connection.synonymus.length > 0 && (
                                        <div className="c-Word__meaningSynonymus">
                                          <em className="c-Word__mr4">
                                            Synonimy:
                                          </em>
                                          {connection.synonymus.map(
                                            (synonym, index) => (
                                              <p
                                                className="c-Word__meaningSynonym"
                                                key={index}
                                              >
                                                {synonym.labels.map(
                                                  (label, index) =>
                                                    label && (
                                                      <em key={index}>
                                                        {label}
                                                      </em>
                                                    )
                                                )}{" "}
                                                <a
                                                  href={`/words/${synonym.id}`}
                                                >
                                                  {synonym.name}
                                                </a>
                                              </p>
                                            )
                                          )}
                                        </div>
                                      )}

                                    {connection.comments && (
                                      <div className="c-Word__remarks">
                                        <em className="c-Word__mr4">Uwagi:</em>
                                        <div
                                          className="c-Word__remarksContent"
                                          dangerouslySetInnerHTML={{
                                            __html: connection.comments,
                                          }}
                                        ></div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <p
                                  className="c-Word__meaningConnectionWord"
                                  key={index}
                                >
                                  {connection.name}
                                </p>
                              )
                            )
                          : ""}
                      </div>
                    ) : (
                      ""
                    )}
                    {item.comments && (
                      <div className="c-Word__remarks">
                        <em className="c-Word__mr4">Uwagi:</em>
                        <div
                          className="c-Word__remarksContent"
                          dangerouslySetInnerHTML={{ __html: item.comments }}
                        ></div>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ol>
          )}

          {data.synonymus && data.synonymus.length > 0 && (
            <div className="c-Word__meaningSynonymus">
              <em className="c-Word__mr4">Synonimy:</em>
              {data.synonymus.map((synonym, index) => (
                <p className="c-Word__meaningSynonym" key={index}>
                  {synonym.labels.map(
                    (label, index) => label && <em key={index}>{label}</em>
                  )}{" "}
                  <a href={`/words/${synonym.id}`}>{synonym.name}</a>
                </p>
              ))}
            </div>
          )}

          {(data.noconnection && data.noconnection.length > 0) ||
          (data.connections && data.connections.length > 0) ? (
            <div className="c-Word__meaningConnection">
              <em className="c-Word__mr4">Połączenia:</em>
              {data.noconnection && data.noconnection.length > 0 && (
                <div className="c-Word__meaningConnectionWrapper">
                  {data.noconnection.map((connection, index) =>
                    connection.indexes ? (
                      <div
                        className="c-Word__meaningConnectionItem"
                        key={index}
                      >
                        <span className="c-Word__colorBasic">
                          <strong>
                            <a href={`/words/${connection.id}`}>
                              {connection.name}
                            </a>
                          </strong>
                          {connection.audio_path ? (
                            <button
                              href={void 0}
                              className="c-Word__audio"
                              onClick={() => {
                                playAudio(connection.audio_path)
                              }}
                            ></button>
                          ) : (
                            ""
                          )}
                        </span>

                        {connection.indexes.map((itemIndex, index) => (
                          <p className="" key={index}>
                            <em>{itemIndex.index_type}. </em>
                            <span
                              className={`${
                                itemIndex.index_type === "cs"
                                  ? "c-Word__cs"
                                  : ""
                              }`}
                            >
                              {itemIndex.name}
                            </span>
                            {itemIndex.audio_path ? (
                              <button
                                href={void 0}
                                className="c-Word__audio"
                                onClick={() => {
                                  playAudio(itemIndex.audio_path)
                                }}
                              ></button>
                            ) : (
                              ""
                            )}
                          </p>
                        ))}
                      </div>
                    ) : (
                      <p className="c-Word__meaningConnectionWord" key={index}>
                        {connection.name}
                      </p>
                    )
                  )}
                </div>
              )}

              {data.connections && data.connections.length > 0
                ? data.connections.map((connection, index) =>
                    connection.indexes ? (
                      <div
                        className="c-Word__meaningConnectionItem"
                        key={index}
                      >
                        <span className="c-Word__colorBasic">
                          <strong>
                            <a href={`/words/${connection.id}`}>
                              {connection.name}
                            </a>
                          </strong>
                        </span>
                        {connection.audio_path ? (
                          <button
                            href={void 0}
                            className="c-Word__audio"
                            onClick={() => {
                              playAudio(connection.audio_path)
                            }}
                          ></button>
                        ) : (
                          ""
                        )}

                        {connection.pronunciation ? (
                          <div className="c-Word__oratory">
                            [<em>wym.</em>{" "}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: connection.pronunciation,
                              }}
                            />{" "}
                            ]
                          </div>
                        ) : (
                          ""
                        )}

                        {connection.shortcuts &&
                          connection.shortcuts.length > 0 && (
                            <div className="c-Word__shortcuts">
                              <span className="c-Word__mr4">
                                <em>w skrócie:</em>
                              </span>
                              {connection.shortcuts.map((shortcut, index) => (
                                <span
                                  className="c-Word__shortcutsItem"
                                  key={index}
                                >
                                  {shortcut.name}
                                </span>
                              ))}
                            </div>
                          )}
                        <div className="c-Word__meaningConnectionWrapper">
                          {connection.indexes &&
                            connection.indexes.length > 0 &&
                            connection.indexes.map((itemIndex, index) => (
                              <p className="" key={index}>
                                <em>{itemIndex.index_type}. </em>
                                <span
                                  className={` ${
                                    itemIndex.index_type === "cs"
                                      ? "c-Word__cs"
                                      : ""
                                  }`}
                                >
                                  {itemIndex.name}
                                </span>
                                {itemIndex.audio_path ? (
                                  <button
                                    href={void 0}
                                    className="c-Word__audio"
                                    onClick={() => {
                                      playAudio(itemIndex.audio_path)
                                    }}
                                  ></button>
                                ) : (
                                  ""
                                )}
                              </p>
                            ))}

                          {connection.qualifiers &&
                            connection.qualifiers.length > 0 &&
                            connection.qualifiers.map((qualifier, index) => (
                              <span
                                className="c-Word__meaningQualifier"
                                key={index}
                              >
                                <em>{qualifier.shortcut}</em>
                              </span>
                            ))}

                          {connection.description && (
                            <div
                              className="c-Word__meaningConnectionDesc"
                              dangerouslySetInnerHTML={{
                                __html: connection.description,
                              }}
                            ></div>
                          )}

                          {connection.meanings &&
                            connection.meanings.length > 0 && (
                              <ol className="c-Word__meanings">
                                {connection.meanings.map((meaning, index) => (
                                  <li className="c-Word__meaning" key={index}>
                                    <div
                                      className="c-Word__meaningConecntions"
                                      dangerouslySetInnerHTML={{
                                        __html: meaning.description,
                                      }}
                                    ></div>
                                  </li>
                                ))}
                              </ol>
                            )}

                          {connection.synonymus &&
                            connection.synonymus.length > 0 && (
                              <div className="c-Word__meaningSynonymus">
                                <em className="c-Word__mr4">Synonimy:</em>
                                {connection.synonymus.map((synonym, index) => (
                                  <p
                                    className="c-Word__meaningSynonym"
                                    key={index}
                                  >
                                    {synonym.labels.map(
                                      (label, index) =>
                                        label && <em key={index}>{label}</em>
                                    )}{" "}
                                    <a href={`/words/${synonym.id}`}>
                                      {synonym.name}
                                    </a>
                                  </p>
                                ))}
                              </div>
                            )}

                          {connection.comments && (
                            <div className="c-Word__remarks">
                              <em className="c-Word__mr4">Uwagi:</em>
                              <div
                                className="c-Word__remarksContent"
                                dangerouslySetInnerHTML={{
                                  __html: connection.comments,
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p className="c-Word__meaningConnectionWord" key={index}>
                        {connection.name}
                      </p>
                    )
                  )
                : ""}
            </div>
          ) : (
            ""
          )}

          {data.comments && (
            <div className="c-Word__remarks">
              <em className="c-Word__mr4">Uwagi:</em>
              <div
                className="c-Word__remarksContent"
                dangerouslySetInnerHTML={{ __html: data.comments }}
              ></div>
            </div>
          )}

          {data.correct_form && (
            <div className="c-Word__remarks">
              <span>
                <em>Uwagi:</em>
              </span>
              <div
                className="c-Word__remarksContent"
                dangerouslySetInnerHTML={{ __html: data.correct_form }}
              ></div>
            </div>
          )}

          {data.parent && data.parent.length > 0 && (
            <div className="c-Word__parent">
              <span>
                Zobacz kompletny artykuł hasłowy: →{" "}
                <a href={`/words/${data.parent[0].id}`}>
                  {data.parent[0].name}
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Word
