import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { API_WORDS } from "../global/api"

import LayoutSubpage from "../layouts/layout_subpage"
import Seo from "../components/Seo"
import Word from "../components/Word/Word"
import ButtonTools from "../components/ButtonTools/ButtonTools"
import Licence from "../components/Licence/Licence"
import Loader from "../components/Loader/Loader"

const WordPage = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  let location = useLocation()

  // Request for word data
  useEffect(() => {
    // Get Word ID from URL
    const wordId = window.location.pathname.split("/").reverse()[0]

    const fetchData = async () => {
      setLoading(true)
      await fetch(`${API_WORDS}/${wordId}`)
        .then(response => {
          if (response.ok) {
            return response
          }
          throw Error(response.status)
        })
        .then(response => response.json())
        .then(data => {
          setData(data.result)
          setLoading(false)
        })
        .catch(error => console.log(error))
    }
    fetchData()
  }, [location])

  return (
    <LayoutSubpage title="Hasło">
      <>
        {loading ? (
          <Loader additionClass="c-Word__loader" />
        ) : (
          data && (
            <>
              <Seo title={`Hasło: ${data.name}`} />
              <Word data={data} key={window.location.pathname} />
              <ButtonTools name={data.name} />
              <Licence />
            </>
          )
        )}
      </>
    </LayoutSubpage>
  )
}
export default WordPage
