import React, { useState, useEffect } from "react"
import ShareButton from "../ButtonTools/ShareButton/ShareButton"

import "./FontSizeChanger.scss"

import FontIncrease from "../../images/icons/fontIncrease.svg"
import FontDecrease from "../../images/icons/fontDecrease.svg"

const FontSizeChanger = () => {
  const [disabledIncrease, setDisabledIncrease] = useState(false)
  const [disabledDecrease, setDisabledDecrease] = useState(false)
  const [value, setValue] = useState(1)
  const [maxValue] = useState(1.4)
  const [minValue] = useState(0.8)

  useEffect(() => {
    const content = document.querySelector(".main-content__container")
    content.style.fontSize = `${value}em`

    if (value === 1) {
      content.removeAttribute("style")
    }

    if (value === maxValue) {
      setDisabledIncrease(true)
    } else {
      setDisabledIncrease(false)
    }

    if (value === minValue) {
      setDisabledDecrease(true)
    } else {
      setDisabledDecrease(false)
    }
  }, [value])

  const handleFontIncrease = () => {
    if (value <= maxValue) {
      setValue(parseFloat((value + 0.1).toFixed(1)))
    }
  }

  const handleFontDecrease = () => {
    if (value >= minValue) {
      setValue(parseFloat((value - 0.1).toFixed(1)))
    }
  }

  return (
    <div className="c-FontSizeChanger">
      <ShareButton
        icon={<FontDecrease />}
        title="Zmniejsz czcionkę"
        click={handleFontDecrease}
        disabled={disabledDecrease}
      />
      <ShareButton
        icon={<FontIncrease />}
        title="Powiększ czcionkę"
        click={handleFontIncrease}
        disabled={disabledIncrease}
      />
    </div>
  )
}

export default FontSizeChanger
